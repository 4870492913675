import { FileInput, Select, Textarea, TextInput } from "flowbite-react";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useSessionContext } from "../../contexts/SessionContext";
import { FormStateType, TipoMovimento } from "../../enums";
import { ContratoModel } from "../../models/ContratoModel";
import { ClienteModel } from "../../models/ClienteModel";
import { defaultOrdemModel, OrdemModel } from "../../models/OrdemModel";
import { ClienteService } from "../../services/ClienteService";
import { ContratoService } from "../../services/ContratoService";
import { OrdemService } from "../../services/OrdemService";
import { findValidationField, FluentValidator } from "../../types";
import CodeUtil from "../../util/CodeUtil";
import { UNKOWN_EXCEPTION } from "../../util/Constants";
import { CodeLabel } from "../CodeLabel";
import { ShowToast } from "../CodeToast";
import { Groupbox } from "../Groupbox";
import ModalForm from "../ModalForm";
import { ModalFormProps } from "../ModalForm/types";
import { MoneyInput } from "../MoneyInput";
import useUserRole from "../../hooks/UserRoleHook";

export const ModalOrder: FC<ModalFormProps> = (props) => {
    const currentSession = useSessionContext();
    const [model, setModel] = useState<OrdemModel>(defaultOrdemModel);
    const [clientes, setClientes] = useState<ClienteModel[]>([]);
    const [contratos, setContratos] = useState<ContratoModel[]>([]);
    const [saldoDisponivelContrato, setSaldoDisponivelContrato] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [errors, setErrors] = useState<FluentValidator[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const currentRole = useUserRole();

    const getClientes = useCallback(async () => {
        try {

            if (currentRole === "CLIENTE") {
                setClientes([]);
                getContratos(currentSession.session?.usuario?.clienteId ?? 0);
                setModel({ ...model, clienteId: currentSession.session?.usuario?.clienteId ?? 0 });
                return;
            }

            let response = await ClienteService.get({ limit: 0, page: 1 });
            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                setClientes([]);
                return;
            }

            setClientes(response.data);
        } catch (error) {
            setClientes([]);
            console.log(error);
        }
    }, [currentSession, currentRole, model, setModel]);

    const getContratos = async (clienteId: number) => {
        try {
            let response = await ContratoService.get({
                limit: 0,
                page: 1,
                clienteId: clienteId,
                status: ["Vigente"]
            });

            if (!response.success) {
                ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                setContratos([]);
                return;
            }

            setContratos(response.data);
        } catch (error) {
            setContratos([]);
            console.log(error);
        }
    };

    const calcularSaldoDisponivel = async (tipo: TipoMovimento, contratoId: number) => {
        setSaldoDisponivelContrato(0);
        if (contratoId === 0) return;

        console.log(contratoId);

        const contratoResponse = await ContratoService.getId(contratoId);
        if (!contratoResponse.success) return;

        const contratos = contratoResponse.data as ContratoModel[];
        if (contratos.length === 0) return;

        const selectedContrato = contratoResponse.data[0] as ContratoModel;
        if (tipo === TipoMovimento.SaqueAporte && selectedContrato.tipo === "Carteira 2") {
            const vigencia = CodeUtil.isNullOrEmpty(selectedContrato.vigencia) ? null : new Date(`${selectedContrato.vigencia}Z`);
            const now = new Date();

            if (vigencia != null && now < vigencia) {
                setSaldoDisponivelContrato(0);
                return;
            }
        }

        if (tipo === TipoMovimento.SaqueAporte || tipo === TipoMovimento.CorrecaoSaldo)
            setSaldoDisponivelContrato(selectedContrato.totalAplicado ?? 0);

        if (tipo === TipoMovimento.SaqueRendimento || tipo === TipoMovimento.Transferencia || tipo === TipoMovimento.Reaporte)
            setSaldoDisponivelContrato(selectedContrato.rendimentoDisponivel ?? 0);
    }

    const handleSelectCliente = async (e: ChangeEvent<HTMLSelectElement>) => {
        setModel({ ...model, clienteId: Number(e.currentTarget.value) });

        await getContratos(Number(e.currentTarget.value));
    };

    const handleSelectTipoOrdem = async (e: ChangeEvent<HTMLSelectElement>) => {
        let tipoOrdem = e.currentTarget.value as TipoMovimento;
        setModel({ ...model, tipo: tipoOrdem });

        await calcularSaldoDisponivel(tipoOrdem, model.contratoId);
    };

    const handleSelectContrato = async (e: ChangeEvent<HTMLSelectElement>) => {
        setModel({ ...model, contratoId: Number(e.currentTarget.value) });

        await calcularSaldoDisponivel(model.tipo, Number(e.currentTarget.value));
    }

    const validate = (): boolean => {
        try {
            let _errors: FluentValidator[] = [];

            if (!model.clienteId || model.clienteId <= 0) {
                _errors.push({ field: "cliente", message: "O cliente deve ser selecionado." });
            }

            if (!model.contratoId || model.contratoId <= 0) {
                _errors.push({ field: "contrato", message: "O contrato deve ser selecionado." });
            }

            if (model.tipo === TipoMovimento.Transferencia && (!model.contratoDestinoId || model.contratoDestinoId <= 0)) {
                _errors.push({ field: "contratoDestino", message: "O contrato de destino deve ser selecionado." });
            }

            if (model.tipo !== TipoMovimento.CorrecaoSaldo && model.valor <= 0) {
                _errors.push({ field: "valor", message: "O valor da ordem deve ser informado." });
            }

            if (model.valor > saldoDisponivelContrato && (model.tipo === TipoMovimento.SaqueAporte || model.tipo === TipoMovimento.SaqueRendimento || model.tipo === TipoMovimento.Reaporte)) {
                _errors.push({ field: "valor", message: "O valor da ordem é maior que o saldo disponível do contrato." });
            }

            if (model.executadaEmDate !== null && model.executadaEmDate > new Date()) {
                _errors.push({ field: "executadaEm", message: "A data da ordem não pode ser maior que hoje." });
            }

            setErrors(_errors);
            return _errors.length === 0;
        } catch (error) {
            ShowToast({ message: UNKOWN_EXCEPTION });
            console.log(error);
            return false;
        }
    };

    const saveOrder = async (): Promise<boolean> => {
        try {
            setSaving(true);
            //Ordens de clientes não possuem data de execução
            if (currentRole === "CLIENTE") setModel({ ...model, executadaEm: undefined, executadaEmDate: null });

            if (!validate()) return false;

            try {
                console.log(model);
                let response = props.state === FormStateType.add ?
                    await OrdemService.add(model, model.comprovanteFile) :
                    await OrdemService.update(model.id ?? 0, model, model.comprovanteFile);

                if (!response.success) {
                    ShowToast({ message: CodeUtil.arrayToStr(response.messages) });
                    return false;
                }

                return true;
            } catch (error) {
                ShowToast({ message: UNKOWN_EXCEPTION });
                console.log(error);
                return false;
            }
        } finally {
            setSaving(false);
        }
    };

    const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length === 0) return;

        setModel({
            ...model,
            comprovanteFile: e.target.files![0],
            comprovanteFilename: e.target.files![0].name
        });
    }

    const onFormLoad = useCallback(async () => {
        if (!props.show || loaded) return;

        await getClientes();
        setLoaded(true);
    }, [props.show, loaded, setLoaded, getClientes]);

    const onFormClose = () => {
        setLoaded(false);
        setModel(defaultOrdemModel);
        setSaldoDisponivelContrato(0);
        setErrors([]);
        props.onClose?.call(this);
    };

    const onFormSave = async () => {
        if (!await saveOrder()) return;

        onFormClose();
        props.onSave?.call(this, undefined);
    };

    const handleChangeDataExecucao = (e: ChangeEvent<HTMLInputElement>) => {
        setModel({
            ...model,
            executadaEm: e.currentTarget.valueAsDate?.toISOString(),
            executadaEmDate: e.currentTarget.valueAsDate
        });
    }

    useEffect(() => {
        onFormLoad();
    }, [onFormLoad]);

    return (
        <ModalForm {...props} 
            isSaving={saving}
            title="Ordem"
            onClose={() => onFormClose()}
            onSave={async () => await onFormSave()}>
            <div className="grid grid-cols-12 p-3 space-y-3">

                {currentRole !== "CLIENTE" &&
                    <div className="form-control mt-3 col-span-12">
                        <CodeLabel htmlFor="selectCliente" className="mb-1" value="Cliente:" />
                        <Select id="selectCliente"
                            helperText={findValidationField(errors, "cliente").message}
                            color={findValidationField(errors, "cliente").isValid ? "gray" : "failure"}
                            value={model?.clienteId}
                            onChange={handleSelectCliente}>
                            <option value={0}>Selecione um cliente</option>
                            {clientes.map((value) => <option key={value.id} value={value.id}>{value.nomeCompleto}</option>)}
                        </Select>
                    </div>}

                <div className="form-control col-span-12">
                    <CodeLabel htmlFor="selectContrato" className="mb-1" value={`${model.tipo === TipoMovimento.Transferencia ? "Contrato de Origem:" : "Contrato:"}`} />
                    <Select id="selectContrato"
                        helperText={findValidationField(errors, "contrato").message}
                        color={findValidationField(errors, "contrato").isValid ? "gray" : "failure"}
                        value={model?.contratoId}
                        onChange={handleSelectContrato}>
                        <option value={0}>Selecione um contrato</option>
                        {contratos.map((value) => <option key={value.id} value={value.id}>{
                            `${value.tipo}
                                    ${(CodeUtil.isNullOrEmpty(value.vigencia) ? "" : `| ${CodeUtil.dateFormat(value.vigencia)}`)}`.trim()
                        }</option>)}
                    </Select>
                </div>

                <div className="form-control col-span-8 mr-3" hidden={model.tipo === TipoMovimento.Aporte || model.tipo === TipoMovimento.Rendimento}>
                    <CodeLabel htmlFor="inputSaldoContrato" className="mb-1" value="Saldo disponível:" />
                    <MoneyInput id="inputSaldoContrato"
                        disabled={true}
                        helperText={findValidationField(errors, "contrato").message}
                        color={findValidationField(errors, "contrato").isValid ? "gray" : "failure"}
                        value={CodeUtil.moneyFormat(saldoDisponivelContrato, false)} />
                </div>

                <div className="form-control col-span-4" hidden={model.tipo === TipoMovimento.Aporte || model.tipo === TipoMovimento.Rendimento}></div>

                {model.tipo !== TipoMovimento.Transferencia ?
                    (<></>) :
                    (<div className="form-control col-span-12">
                        <CodeLabel htmlFor="selectContratoDestino" className="mb-1" value="Contrato de Destino:" />
                        <Select id="selectContratoDestino"
                            helperText={findValidationField(errors, "contratoDestino").message}
                            color={findValidationField(errors, "contratoDestino").isValid ? "gray" : "failure"}
                            value={model?.contratoDestinoId}
                            onChange={(e) => setModel({ ...model, contratoDestinoId: Number(e.currentTarget.value) })}>
                            <option value={0}>Selecione um contrato</option>
                            {contratos.map((value) => <option key={value.id} value={value.id}>{
                                `${value.tipo}
                                    ${(CodeUtil.isNullOrEmpty(value.vigencia) ? "" : `| ${CodeUtil.dateFormat(value.vigencia)}`)}`.trim()
                            }</option>)}
                        </Select>
                    </div>)}

                <div className="form-control col-span-8 mt-3 mr-3">
                    <CodeLabel htmlFor="selectTipo" className="mb-1" value="Tipo:" />
                    <Select id="selectTipo"
                        value={model.tipo}
                        onChange={handleSelectTipoOrdem}>
                        {currentRole !== "CLIENTE" && <option value={TipoMovimento.CorrecaoSaldo}>Ajuste de Saldo</option>}
                        <option value={TipoMovimento.Aporte}>Aporte</option>
                        <option value={TipoMovimento.SaqueAporte}>Saque de Aporte</option>
                        {currentRole !== "CLIENTE" && <option value={TipoMovimento.Rendimento}>Rendimento</option>}
                        <option value={TipoMovimento.SaqueRendimento}>Saque de Rendimentos</option>
                        {currentRole !== "CLIENTE" && <option value={TipoMovimento.Transferencia}>Transferência de Rendimentos</option>}
                        {currentRole !== "CLIENTE" && <option value={TipoMovimento.Reaporte}>Reaporte de Rendimentos</option>}
                    </Select>
                </div>

                {currentRole !== "CLIENTE" &&
                    <div className="form-control col-span-4">
                        <CodeLabel className="mb-1" value="Executada Em:" />
                        <TextInput type="date"
                            max={new Date().toISOString()}
                            helperText={findValidationField(errors, "executadaEm").message}
                            color={findValidationField(errors, "executadaEm").isValid ? "gray" : "failure"}
                            value={CodeUtil.fromISOString(model.executadaEmDate?.toISOString())}
                            onChange={handleChangeDataExecucao} />
                    </div>}

                <div className="form-control col-span-8 md:col-span-4">
                    <CodeLabel className="mb-1" value="Valor da Ordem:" />
                    <MoneyInput
                        placeHolder="0,00"
                        allowNegative={model.tipo === TipoMovimento.CorrecaoSaldo}
                        helperText={findValidationField(errors, "valor").message}
                        color={findValidationField(errors, "valor").isValid ? "gray" : "failure"}
                        onChange={(_value, numberValue) => setModel({ ...model, valor: numberValue })}
                        value={CodeUtil.moneyFormat(model?.valor, false)} />
                </div>

                <div className="col-span-12">
                    <Groupbox className="py-3">
                        <div className="grid grid-cols-12 space-y-3">
                            <div className="form-control col-span-12 mt-3">
                                <CodeLabel htmlFor="inputComprovante" className="mb-1" value="Comprovante:" />
                                <FileInput id="inputComprovante" multiple={false} onChange={handleFileInput}
                                    accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.numbers,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                            </div>

                            {(currentRole !== "CLIENTE" || props.state === FormStateType.view) &&
                                <div className="form-control col-span-12">
                                    <CodeLabel className="mb-1" value="Observações:" />
                                    <Textarea
                                        value={model.observacoesCliente}
                                        onBlur={(e) => setModel({ ...model, observacoesCliente: e.currentTarget.value })}
                                        rows={5} />
                                </div>}
                        </div>
                    </Groupbox>
                </div>
            </div>
        </ModalForm >
    )
}